import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

Sentry.init({
  dsn: 'https://3102f701bc13676e1cac123acfa8579b@o4505891276521472.ingest.us.sentry.io/4507340548931584',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development' && process.env.REACT_APP_ENV_NAME !== 'test',
  beforeSend: (event) => {
    if (typeof window && window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

declare global {
  type DbDocument<T> = {
    id: string;
  } & T;
}
