import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { themeLocaleMap } from './constants/styles';
import { useLocale } from './contexts/locale.context';
import { SnackbarProvider } from './contexts/snackbar.context';
import { dateLocaleMap } from './utils/dates';
import lazyWithRetry from './utils/lazyWithRefresh';
import { useLocalStorage } from './utils/useLocalStorage';

const QR = lazyWithRetry(() =>
  import(/* webpackChunkName: "QR" */ './QR/QR').then((module) => ({
    default: module.default,
  }))
);

const AppRouter = lazyWithRetry(() =>
  import(/* webpackChunkName: "AppRouter" */ './AppRouter').then((module) => ({
    default: module.default,
  }))
);

const RootRouter = () => {
  const { lang } = useLocale();
  const [darkMode] = useLocalStorage<string>('darkMode');

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: darkMode ? 'dark' : 'light',
            primary: {
              light: '#FF8330',
              main: '#EC5E00',
              dark: '#D94D01',
            },
            secondary: {
              light: '#42a5f5',
              main: '#1976d2',
              dark: '#1565c0',
            },
            background: {
              default: darkMode ? '#222' : '#EFF0F4',
              paper: darkMode ? '#111' : '#ffffff',
            },
          },
          shape: {
            borderRadius: 8,
          },
        },
        themeLocaleMap[lang]
      ),
    [darkMode, lang]
  );

  useEffect(() => {
    if (typeof window === 'object' && typeof window.document?.body?.style === 'object') {
      window.document.body.style.backgroundColor = theme.palette.background.default;
      window.document.body.style.overscrollBehaviorY = 'contain';
    }
  }, [theme.palette.background.default]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: typeof window === 'object' ? window.innerHeight : '100vh',
          color: darkMode ? '#fff' : '#000',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocaleMap[lang]}>
          <SnackbarProvider>
            <SWRConfig
              value={{
                fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
                shouldRetryOnError: false,
                revalidateOnFocus: false,
              }}
            >
              <Suspense fallback={null}>
                <Router>
                  <Routes>
                    <Route path="/qr/:cafeSlug/*" element={<QR />} />
                    <Route path="*" element={<AppRouter />} />
                  </Routes>
                </Router>
              </Suspense>
            </SWRConfig>
          </SnackbarProvider>
        </LocalizationProvider>
      </Box>
    </ThemeProvider>
  );
};

export default RootRouter;
