/* eslint-disable no-constant-condition */
import { useCallback, useMemo, useSyncExternalStore } from 'react';

let listeners: (() => void)[] = [];

export const useLocalStorage = <T>(key: string): [T | undefined, (value?: T) => void] => {
  const subscribe = useCallback((listener: () => void) => {
    listeners = [...listeners, listener];

    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  }, []);

  const getSnapShot = useCallback(() => {
    try {
      return typeof localStorage ? localStorage.getItem(key) : null;
    } catch {
      return null;
    }
  }, [key]);

  const storeValue = useSyncExternalStore(subscribe, getSnapShot);

  const changeValue = useCallback(
    (newValue?: T) => {
      try {
        if (newValue === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }

        listeners.forEach((listener) => listener());
      } catch {
        /* empty */
      }
    },
    [key]
  );

  const value = useMemo(() => {
    try {
      const result = storeValue ? (JSON.parse(storeValue) as T) : undefined;

      return result;
    } catch {
      return undefined;
    }
  }, [storeValue]);

  return useMemo(() => [value, changeValue], [changeValue, value]);
};
