import { LocaleProvider } from './contexts/locale.context';
import RootRouter from './RootRouter';

function App() {
  return (
    <LocaleProvider>
      <RootRouter />
    </LocaleProvider>
  );
}

export default App;
