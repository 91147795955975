import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

export type Lang = 'en' | 'es' | 'uk' | 'ru';

type LocaleContextValue = {
  lang: Lang;
  setLocale: (lang: Lang) => void;
};

const LocaleContext = createContext<LocaleContextValue | null>(null);

export const useLocale = (): LocaleContextValue => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context;
};

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const defaultLang = useMemo(() => {
    try {
      const langs = (typeof navigator === 'object' ? navigator.languages : []).map((l) => l.split('-')[0]);
      const browserLang = langs.find((l) => l === 'en' || l === 'es' || l === 'uk' || l === 'ru') as Lang;
      const lsLang = typeof window === 'object' ? window.localStorage?.getItem('language') : undefined;
      const isLsLangValid = lsLang === 'en' || lsLang === 'es' || lsLang === 'uk' || lsLang === 'ru';
      return isLsLangValid ? lsLang : browserLang || 'es';
    } catch {
      return 'es';
    }
  }, []);

  const [lang, setLang] = useState<Lang>(defaultLang);

  useEffect(() => {
    document.head.lang = lang;
  }, [lang]);

  const value: LocaleContextValue = useMemo(
    () => ({
      lang,
      setLocale: (lang: Lang) => {
        try {
          if (typeof window === 'object' && window.localStorage?.setItem) {
            window.localStorage.setItem('language', lang);
          }
        } catch {
          /** empty */
        }
        setLang(lang);
      },
    }),
    [lang]
  );

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
