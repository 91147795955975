import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

type SnackbarContextValue = {
  addSnackbar: (title: string, type?: AlertColor) => void;
};

const SnackbarContext = createContext<SnackbarContextValue | null>(null);

export const useSnackbar = (): SnackbarContextValue => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
};

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState('');
  const [type, setType] = useState<AlertColor>('success');

  const handleClose = useCallback(() => {
    setTitle('');
  }, []);

  const addSnackbar = useCallback((title: string, type: AlertColor = 'success') => {
    setTitle(title);
    setType(type);
  }, []);

  const value = useMemo(() => ({ addSnackbar }), [addSnackbar]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        open={!!title}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
