import { Paper, PaperProps, SxProps } from '@mui/material';
import { ukUA, enUS, esES, ruRU, nlNL, frFR, deDE, itIT, plPL, roRO, trTR, ptPT } from '@mui/material/locale';

export const themeLocaleMap = {
  uk: ukUA,
  en: enUS,
  es: esES,
  ru: ruRU,
  nl: nlNL,
  fr: frFR,
  de: deDE,
  it: itIT,
  pl: plPL,
  ro: roRO,
  tr: trTR,
  pt: ptPT,
};

export const boxShadow = 'rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 0px 30px -5px ';

export const borberBoxShadow = 'rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 0px 30px -5px ';

export const menuProps: SxProps = {
  mt: 1,
  borderRadius: 1,
  boxShadow,
  li: {
    borderRadius: 1,
  },
};

export const paperProps: SxProps = {
  borderRadius: 1,
  boxShadow,
  ul: {
    p: '2px',
    m: 0,
    li: {
      m: '2px',
      borderRadius: 1,
    },
  },
};

export const selectMenuProps: SxProps = {
  mt: 1,
  borderRadius: 1,
  boxShadow: `${boxShadow} !important`,
  ul: {
    p: 0,
    li: {
      m: 1,
      borderRadius: 1,
    },
  },
};

type Props = PaperProps & {
  isCard?: boolean;
};

export const StyledPaper = ({ isCard = true, ...props }: Props) =>
  isCard ? <Paper {...props} sx={{ ...paperProps, ...(props.sx || {}) }} /> : <div {...props} />;
